<template>
  <div>
    <v-nepali-date-picker
      class="el-input"
      classValue="el-input__inner"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="syncedValue"
    />
  </div>
</template>

<script>
import VNepaliDatePicker from "@/components/v-nepalidatepicker.vue";

export default {
  name: "NepaliDatePicker",
  components: { VNepaliDatePicker },
  props: ["value"],
  computed: {
    syncedValue: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style lang="sass" scoped></style>
